
import { Switch, Route, } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/incidentTable.css"
import "./assets/styles/responsive.css";
import "./assets/styles/Student.Results.Slip.css";
import "./assets/styles/learning.materials.css";

import './assets/styles/scrollbar.css'
import './assets/styles/Login.Page.css'
import './assets/styles/student.finance.css'
import './assets/styles/Password.forgot.css'
import './assets/styles/Payments.Top.Header.css'
import './assets/styles/Student.payments.css'
import './assets/styles/Discussions.css'
import './assets/styles/Academic.results.slip.css'
import React, {Suspense, useEffect, useState} from 'react'
import axios from 'axios';
import { Spin } from "antd";
import AutoLogout from "./Auto.Logout.js";

const ProductsPage = React.lazy(() => import("./Shool.Products.Sales/index.js"));
const ParentsWithAreasPage = React.lazy(() => import("./pages/Parents.With.Areas.js"));
const StudentAcademicResultsPage = React.lazy(() => import('./pages/Student.academic.results.js'));
const StudentQcReportPage = React.lazy(() => import('./pages/Student.Qc.Report.js'));
const TeacherLogBookPage = React.lazy(() => import('./pages/Teacher.log.book.js'));
const ChatGPTBot = React.lazy(() => import('./e.learning/pages/chat.bot.gpt.js'));
const SchoolPage = React.lazy(() => import('./pages/School.page.js'));

const SignIn = React.lazy(() => import('./pages/SignIn.js'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const Password = React.lazy(() => import("./pages/password"));
const Main = React.lazy(() => import("./components/layout/Main"));

// const NewChatBot = React.lazy(() => import("./e.learning/pages/chat.bot.gemini.js"));
const RegistrationSuccess = React.lazy(() => import("./pages/Subscription.activation.js"));
const ThirdPartLoginSuccessPage = React.lazy(() => import("./pages/Third.part.login.success.js"));
const TeachersTable = React.lazy(() => import("./pages/Teachers.js"));
const SubjectsTable = React.lazy(() => import("./pages/Subject.js"));
const ClassesTable = React.lazy(() => import("./pages/Classes.js"));
const StudentTable = React.lazy(() => import("./pages/Student.js"));
const AssessmentGradeTable = React.lazy(() => import("./pages/Assessment.Grades.js"));
const AssessmentMarkTable = React.lazy(() => import("./pages/Assessment.marks.js"));
const StudentAssessmentTable = React.lazy(() => import("./pages/Assessments.js"));
const TextEditor = React.lazy(() => import("./pages/TextEditor.js"));
const StudentDisciplinaryRecordsTable = React.lazy(() => import("./pages/student.disciplinary.records.js"));
const StudentAttendanceTable = React.lazy(() => import("./pages/Student.attendance.js"));
const Timetable = React.lazy(() => import("./pages/Roster.Schedule.js"));
const TeacherDashboard = React.lazy(() => import("./pages/Dashboard.Teacher.js"));
const StudentDashboard = React.lazy(() => import("./pages/Dashboard.Student.js"));
const ParentDashboard = React.lazy(() => import("./pages/Dashboard.Parent.js"));
const TermsAndConditions = React.lazy(() => import("./pages/TermsAndConditions.js"));
const PasswordResetPage = React.lazy(() => import("./pages/Password.forgot.js"));
const StudentPaymentsPage = React.lazy(() => import("./pages/Student.Payments.js"));
const SchoolsTable = React.lazy(() => import("./pages/Schools.js"));
const SubscribePage = React.lazy(() => import("./pages/Subscription.activation.js"));
const SchoolIncomesPage = React.lazy(() => import("./pages/School.incomes.js"));
const SchoolExpensesPage = React.lazy(() => import("./pages/School.expenses.js"));
const SchoolLedgerPage = React.lazy(() => import("./pages/School.Ledge.js"));
const StudentAssessmentsReportsPage = React.lazy(() => import("./pages/Student.Assessments.Reports.js"));
const NewDiscussionForumPage = React.lazy(() => import("./e.learning/pages/discussions.js"));
const StudentResultSlip = React.lazy(() => import("./parent.dashboard/student.results.slip.js"));
const StudentInClassTestsAndExercisesResults = React.lazy(() => import("./parent.dashboard/student.inclass.test.js"));
const StudentTimetable = React.lazy(() => import("./parent.dashboard/student.timetable.js"));
const LearningMaterials = React.lazy(() => import("./e.learning/pages/learning.materials.js"));
const StudentLearningMaterials = React.lazy(() => import("./parent.dashboard/learning.materials.js"));
const StudentFinancePage = React.lazy(() => import("./parent.dashboard/student.finance.js"));


function App() {
  const [permissions, setPermissions] = useState([])

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URI+"/auth/my/permissions/", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
      setPermissions(data.data.res);
    }).catch(error =>{
      // requestError(error)
    })
  },[])
  
  return (
    <Suspense 
      fallback={
        <div className="page-loading-icon">
          <Spin size="large" />
        </div>
      } 
    >
    <div className="page">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/forgot-password" exact component={PasswordResetPage} />
        {/* <Route path="/terms-and-conditions" exact component={TermsAndConditions} /> */}
        <Route exact path="/terms-and-conditions"  render={(props) =><TermsAndConditions permissions={permissions} token={localStorage.getItem("token")}/>} />

        <Route path="/" exact component={SignIn} />
        <Route path="/registration/success" exact component={RegistrationSuccess} />
        <Route path="/login/success" exact component={ThirdPartLoginSuccessPage} />
        <Route path="/schools" exact component={SchoolsTable} />
        <Route exact path="/password" render={(props) => <Password token={localStorage.getItem("token")}/>}   />
        <Route exact path="/student-results-slip" render={(props) => <StudentResultSlip permissions = {permissions} token={localStorage.getItem("token")}/>}   />
        <Route exact path="/student-inclass-results" render={(props) => <StudentInClassTestsAndExercisesResults permissions = {permissions} token={localStorage.getItem("token")}/>}   />
        <Route exact path="/student-timetable" render={(props) => <StudentTimetable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
        <Route exact path="/parent-learning-materials" render={(props) => <StudentLearningMaterials permissions = {permissions} token={localStorage.getItem("token")}/>}   />

        <Main permissions={permissions}>
          <AutoLogout timeout={900000}/> 
          <Route path="/subscribe" exact component={SubscribePage} />
          <Route exact path="/teacher-dashboard"  render={(props) =><TeacherDashboard permissions={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/school"  render={(props) =><SchoolPage permissions={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/student-dashboard"  render={(props) =><StudentDashboard permissions={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/parent-dashboard"  render={(props) =><ParentDashboard permissions={permissions} token={localStorage.getItem("token")}/>} />
          <Route exact path="/school-staff" render={(props) => <TeachersTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/students" render={(props) => <StudentTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/subjects" render={(props) => <SubjectsTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/assessment-grades" render={(props) => <AssessmentGradeTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/assessment-marks" render={(props) => <AssessmentMarkTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/assessments" render={(props) => <StudentAssessmentTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/classes" render={(props) => <ClassesTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/disciplinary-records" render={(props) => <StudentDisciplinaryRecordsTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/text-editor" render={(props) => <TextEditor permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/attendance-register" render={(props) => <StudentAttendanceTable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/Timetable" render={(props) => <Timetable permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/fees-payments" render={(props) => <StudentPaymentsPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/student-financial-account" render={(props) => <StudentFinancePage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/school-incomes" render={(props) => <SchoolIncomesPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/school-expenses" render={(props) => <SchoolExpensesPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/school-ledger" render={(props) => <SchoolLedgerPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/assessment-reports" render={(props) => <StudentAssessmentsReportsPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/discussion-forum" render={(props) => <NewDiscussionForumPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/learning-materials" render={(props) => <LearningMaterials permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/new-chat-bot" render={(props) => <ChatGPTBot permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/staff-log-book" render={(props) => <TeacherLogBookPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/student-qc-reports" render={(props) => <StudentQcReportPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/student-academic-report" render={(props) => <StudentAcademicResultsPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/parents-with-areas" render={(props) => <ParentsWithAreasPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
          <Route exact path="/products" render={(props) => <ProductsPage permissions = {permissions} token={localStorage.getItem("token")}/>}   />
        </Main>
      </Switch>
    </div>
    </Suspense>
  );
}

export default App;
